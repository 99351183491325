




















































































































































































































































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import MediaCommissionItem from '@/components/media/CommissionItem.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  find: (b: string, c: string) => ApiResource.Commission,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    MediaCommissionItem,
  },
  data() {
    return {
      amountEarned: 0 as number,
      amountFee: 0 as number,
      amountTotal: 0 as number,
      calcError: null,
      calcTimeout: null as null|number,
      calculating: false,
      error: null,
      form: {
        price: 0.00, // x CURRENCY_PRECISION => int
        unit: 'hour' as 'hour'|'flat_rate',
        qty: 1,
        purpose: '',
        description: '',
      },
      loading: false,
      ready: false,
      preview: false,
      validation: {
        unit_type: null,
        unit_qty: null,
        unit_price: null,
        purpose: null,
        description: null,
      } as { [index: string]: string|null },
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      find: 'business/commission/Find',
      listItems: 'business/commission/item/List',
    }),
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    business(): ApiResource.Business {
      // Fallback to finding by ID automatically
      return this.detect(this.VANITY);
    },
    commission(): ApiResource.Commission {
      return this.find(this.business.id, this.COMMISSION_ID);
    },
    item(): ApiResource.CommissionItem {
      return {
        id: 'preview',
        object: 'commission_item',
        commission_id: 'preview',
        unit_price: parseInt((this.form.price * 100).toFixed(0), 10),
        unit_qty: this.form.qty,
        unit_type: this.form.unit,
        tax_amount: 0,
        tax_country: 'XX',
        tax_percent: 0,
        total_amount: parseInt((this.amountTotal * 100).toFixed(0), 10),
        fee_amount: parseInt((this.amountFee * 100).toFixed(0), 10),
        destination_amount: parseInt((this.amountEarned * 100).toFixed(0), 10),
        currency: 'EUR',
        is_paid: false,
        is_refunded: false,
        purpose: this.form.purpose,
        description: this.form.description,
        created_at: Date.now() / 1000,
        updated_at: Date.now() / 1000,
      };
    },
    unitOptions() {
      const UNIT_TYPES = [
        'flat_rate',
        'hour',
      ];
      const options: any[] = [];

      UNIT_TYPES.forEach((type) => {
        options.push({
          value: type,
          text: this.$t(`unit.per.${type}`),
        });
      });

      return options;
    },
  },
  watch: {
    'form.price': {
      immediate: false,
      handler() {
        this.calculateFeeAfterChange();
      },
    },
    'form.qty': {
      immediate: false,
      handler() {
        this.calculateFeeAfterChange();
      },
    },
  },
  methods: {
    showPreview() {
      this.ready = false;
      this.preview = true;
      this.calculateFee().then(() => {
        this.ready = true;
      });
    },
    calculateFeeAfterChange() {
      if (this.calcTimeout) window.clearTimeout(this.calcTimeout);
      this.calcTimeout = window.setTimeout(this.calculateFee, 750);
    },
    async calculateFee() {
      const totalAmount = this.form.price * (this.form.qty || 0);

      if (totalAmount < 5.00 || this.calculating || this.preview) return;
      this.calculating = true;

      const unit = parseInt((this.form.price * 100).toFixed(0), 10);

      const fee = await this.$store.dispatch('business/commission/item/CalculateFee', {
        unit_qty: this.form.qty,
        unit_price: unit,
        currency: 'EUR',
        fee_percent: this.commission.fee_percent,
      }).catch((e) => { this.calcError = e; });

      if (!fee) return;

      this.calculating = false;

      const feeAmount = fee.amount / 100;

      this.amountFee = feeAmount;
      this.amountEarned = totalAmount - feeAmount;
      this.amountTotal = totalAmount;
    },
    async createItem() {
      this.preview = false;

      if (this.validate()) return;

      this.loading = true;

      const data = await this.$store.dispatch('business/commission/item/Create', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.COMMISSION_ID,
        form: {
          description: this.form.description,
          purpose: this.form.purpose,
          unit_qty: this.form.qty,
          unit_price: parseInt((this.form.price * 100).toFixed(0), 10),
          unit_type: this.form.unit,
          'validate:currency': 'EUR',
          'validate:destination_amount': parseInt((this.amountEarned * 100).toFixed(0), 10),
          'validate:fee_amount': parseInt((this.amountFee * 100).toFixed(0), 10),
          'validate:total_amount': parseInt((this.amountTotal * 100).toFixed(0), 10),
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;

      if (!data) return;

      this.$a.goal(this.$a.goals.CommissionItemCreate);

      this.$router.push({
        name: 'dashboard.commission.item',
        params: {
          VANITY: this.VANITY,
          COMMISSION_ID: this.COMMISSION_ID,
          ITEM_ID: data.id,
        },
      });
    },
    validate() {
      const v: { [index: string]: string|null } = {};

      if (!this.form.description) {
        v.description = 'notOptional';
      } else if (this.form.description.length > 2000) {
        v.description = 'length';
      }

      if (!this.form.purpose) {
        v.purpose = 'notOptional';
      } else if (this.form.purpose.length > 255) {
        v.purpose = 'length';
      }

      if (!this.form.price) {
        v.unit_price = 'notOptional';
      } else if (this.form.price < 0.01) {
        v.unit_price = 'min';
      }

      if (!this.form.qty) {
        v.unit_qty = 'notOptional';
      } else if (this.form.qty < 0.01) {
        v.unit_qty = 'min';
      }

      if (!this.form.unit) {
        v.unit_type = 'notOptional';
      } else if (!['hour', 'flat_rate'].includes(this.form.unit)) {
        v.unit_type = 'in';
      }

      this.validation = v;

      return !Object.keys(v).every((k) => v[k] === null);
    },
  },
});
