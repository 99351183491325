var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MediaComponent',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('BaseTable',{staticClass:"tabular-nums",class:{
        'min-w-2xs': _vm.isCustomer,
        'min-w-xs': _vm.isBusiness,
      }},[_c('thead',[_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('status.tag'))}}),_c('th',{attrs:{"align":"right"}},[(_vm.item.is_forgiven)?_c('span',{domProps:{"textContent":_vm._s(_vm.$t('status.forgiven'))}}):(_vm.item.is_refunded)?_c('span',{domProps:{"textContent":_vm._s(_vm.$t('status.refunded'))}}):(_vm.item.is_paid)?_c('span',{staticClass:"has-text-link",domProps:{"textContent":_vm._s(_vm.$t('status.paid'))}}):_c('span',{staticClass:"has-text-danger",domProps:{"textContent":_vm._s(_vm.$t('status.unpaid'))}})])])]),_c('tbody',[_c('tr',[_c('td',{domProps:{"textContent":_vm._s(_vm.$t(("unit." + (_vm.item.unit_type))))}}),_c('td',{attrs:{"align":"right"},domProps:{"textContent":_vm._s(_vm.$n(_vm.item.unit_price / _vm.CURRENCY_DIVISOR, _vm.CURRENCY))}})]),_c('tr',[_c('td',{domProps:{"textContent":_vm._s(_vm.$t('qty'))}}),_c('td',{attrs:{"align":"right"},domProps:{"textContent":_vm._s(_vm.item.unit_qty)}})]),(_vm.isBusiness)?_c('tr',[_c('td',{domProps:{"textContent":_vm._s(_vm.$t('total'))}}),_c('td',{attrs:{"align":"right"},domProps:{"textContent":_vm._s(_vm.$n(_vm.item.total_amount / _vm.CURRENCY_DIVISOR, _vm.CURRENCY))}})]):_vm._e(),(_vm.isBusiness)?_c('tr',[(!_vm.isBusinessPreview)?_c('td',{domProps:{"textContent":_vm._s(_vm.$t('fee_incl_tax', {
              taxPercent: parseFloat(_vm.item.tax_percent).toPrecision(2)
            }))}}):_c('td',{domProps:{"textContent":_vm._s(_vm.$t('fee', {
              taxPercent: parseFloat(_vm.item.tax_percent).toPrecision(2)
            }))}}),_c('td',{attrs:{"align":"right"},domProps:{"textContent":_vm._s(("(" + (_vm.$n(_vm.item.fee_amount / _vm.CURRENCY_DIVISOR, _vm.CURRENCY)) + ")"))}})]):_vm._e()]),_c('tfoot',[(_vm.isBusiness)?_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('earnings'))}}),_c('th',{attrs:{"align":"right"},domProps:{"textContent":_vm._s(_vm.$n(_vm.item.destination_amount / _vm.CURRENCY_DIVISOR, _vm.CURRENCY))}})]):(_vm.isCustomer)?_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('total'))}}),_c('th',{attrs:{"align":"right"},domProps:{"textContent":_vm._s(_vm.$n(_vm.item.total_amount / _vm.CURRENCY_DIVISOR, _vm.CURRENCY))}})]):_vm._e()])]),_vm._t("action-button")]},proxy:true}],null,true)},[_vm._t("purpose",[_c('strong',{domProps:{"textContent":_vm._s(_vm.item.purpose)}})],null,_vm.item),_c('div',{staticClass:"mb-2"},[_c('BaseTextPlain',{attrs:{"text":_vm.item.description}})],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }