




















































































































import Vue from 'vue';
import { ApiResource } from '@/types';

export default Vue.extend({
  props: {
    isBusiness: Boolean,
    isBusinessPreview: Boolean,
    isCustomer: Boolean,
    item: {
      required: true,
      type: Object as () => ApiResource.CommissionItem,
      validator(obj: any): obj is ApiResource.CommissionItem {
        return obj.object === 'commission_item';
      },
    },
  },
  computed: {
    CURRENCY(): string {
      return this.item.currency.toUpperCase();
    },
    CURRENCY_DIVISOR(): number {
      // Currently, all supported currencies [EUR] are 2 decimal
      return 100;
    },
  },
});
